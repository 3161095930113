<!-- 数据中心-学生成绩 -->
<template>
    <div class="content-main">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content_class">
                <div class="header-menus" v-if="isShowTopTabs">
                    <div class="tabbed-page-wrap">
                        <el-scrollbar
                            ref="scroll"
                            @wheel.native.prevent="changeScroll"
                            wrap-style="overflow-y:hidden;"
                            style="display:flex;"
                        >
                        <div v-for="(item, index) of tabList" :key="index" :class="['tabbed-page-item', { current: item.sel }]" @click="changeTabs(item)">
                            <el-tooltip :content="item.name" placement="top-start">
                                <div class="tabbed-page-item-name">
                                    {{ item.name }}
                                </div>
                            </el-tooltip>
                        </div>
                        </el-scrollbar>
                    </div>

                </div>
                <div class="filter-wrap" v-if="isShowTopTabs">
                    <expand-filter
                        :closeWidth="680"
                        :formData="formData"
                        marginBottom="0px"
                        @clickBtn="clickBtn"
                        @changeSel="changeSel"
                        @changeBtnFormType="changeBtnFormType"
                    ></expand-filter>
                </div>
                <div class="table-wrap" ref="tableWrap" v-loading="loadingTable">
                    <div class="table_list_box" v-if="tableData && tableData.length > 0">
                        <div class="each_box" v-for="(item, index) of tableData" :key="index">
                            <div @click="handleDetail(item)">
                                <div class="each_headImg">
                                    <img v-if="item.stuImg" :src="item.stuImg" alt="">
                                    <img v-else-if="item.sex == '1'" :src="manHeadImg" alt="">
                                    <img v-else-if="item.sex == '2'" :src="womanHeadImg" alt="">
                                </div>
                                <div class="each_name">{{item.studentName}}</div>
                            </div>
                        </div>

                    </div>
                    <div v-else class="no_data_box">
                        <div class="error_box">
                            <div class="error_img_box"><img :src="errorImg" alt=""></div>
                            <div class="error_text">暂无数据</div>
                        </div>
                    </div>
                </div>
                <Pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />

            </div>
            <dialog-wrapper
                :dialogObj="studentScoreDialogObj"
                @handleClose="handleStudentScoreClose"
            >
                <div class="detail_box">
                    <div class="detail_head">
                        <div class="info_img" v-if="studentScoreInfo.stuImg">
                            <img
                                :src="studentScoreInfo.stuImg"
                                alt=""
                                class="img_style"
                            />
                        </div>
                        <div class="info_img" v-else>
                            <img
                                :src="studentScoreInfo.sex == '1' ? manHeadImg : womanHeadImg"
                                alt=""
                                class="img_style"
                            />
                        </div>
                        <div class="info_nameSex">
                            <div class="info_text"> {{ studentScoreInfo.studentName || "-" }}</div>
                            <div class="info_content">
                                <div class="info_item">
                                    <div class="each_item">
                                        <div class="info_title">性别：</div>
                                        <div class="text">{{ studentScoreInfo.sex == '1' ? "男" : studentScoreInfo.sex == '2' ? "女" : "-" }}</div>
                                    </div>
                                    <div class="each_item">
                                        <div class="info_title">唯一号：</div>
                                        <el-tooltip class="item" effect="dark" :content="studentScoreInfo.onlyCode" placement="left">
                                            <div class="text">{{ studentScoreInfo.onlyCode || "-" }}</div>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="info_item">
                                    <div class="each_item">
                                        <div class="info_title">班级：</div>
                                        <div class="text">{{ studentScoreInfo.organName || '-' }}</div>
                                    </div>
                                    <div class="each_item">
                                        <div class="info_title">班主任：</div>
                                        <div class="text">{{ studentScoreInfo.masterName || "-" }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table-data
                        v-loading="studentloadingTable"
                        id="stuTable"
                        ref="stuTable"
                        :config="score_table_config"
                        :tableData="scoreDataList"
                        :headerStyle="{height: '50px'}"
                        :rowStyle="{height: '50px'}"
                        >
                        <template v-slot:operation="slotData">
                            <el-button
                                class="primary"
                                @click="handleDetails(slotData.data)"
                                >个人成绩分析
                            </el-button>
                        </template>
                    </table-data>
                    <Pagination
                        :total="score_total"
                        :page.sync="scorelistQuery.pageNum"
                        :limit.sync="scorelistQuery.pageRow"
                        @pagination="getScoreList"
                    />
                </div>
            </dialog-wrapper>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
    </div>
</template>

<script>
import {
    // 业务组件
    Pagination,
    DialogWrapper,
    // 功能组件
    Error,
    Loading,
    // 工具函数
    throttle,
    debounce,
} from "common-local";
// 逻辑运算
import TableData from "./Sub/TableData";
import {mapState} from "vuex";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter.vue";
import onResize from "@/mixins/onResize"
import { listToTree } from "@/libs/utils.js";

export default {
    mixins: [onResize],
    name: "SchCenterStudentExamScrollWrapper",
    components: {
        ExpandFilter,
        TableData,
        Pagination,
        DialogWrapper,
        Error,
        Loading,
    },
    data() {
        return {
            activeItem: null,
            isShowTopTabs: false,
            firstTime: false,
            // 头部筛选
            formData: {
                btnFormType: true,
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "选择年级",
                        key: "gradeId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "选择级部",
                        key: "levelId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: [],
                        placeholder: "班级(可多选)",
                        key: "classIds",
                        list: [],
                        multiple: true,
                        collapseTags: true,
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "学生姓名",
                        key: "studentName",
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                    },
                ],
            },
            treeDatas: [],
            // 分页器
            total: 0,
            tableData:[],
            listQuery: {
                pageNum: 1,
                pageRow: 70,
                studentName: "",
                schoolId: "",
                gradeId: "",
                levelId: "",
                classIds: [],
                sortFiled: "4",  // 排序字段 1姓名 2唯一号 3年级班级 4年级班级学号
            },
            // 弹出框配置
            studentScoreDialogObj: {
                title: "学生成绩",
                dialogVisible: false,
                width: "960px",
            },
            studentScoreInfo: {}, // 学生信息
            scoreDataList: [], // 成绩数据
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: false,
            studentloadingTable: false,
            score_table_config: {
                thead: [
                    {
                        label: "考试名称",
                        prop: "examName",
                        align: "center",
                    },
                    {
                        label: "学期",
                        type: "function",
                        align: "center",
                        callBack(row) {
                            return row.semester == "1" ? "上学期" : "下学期";
                        },
                    },
                    {
                        label: "考试类型",
                        prop: "examTypeName",
                        align: "center",
                    },
                    {
                        label: "科目",
                        prop: "examSubjects",
                        align: "center",
                    },
                    {
                        label: "总成绩",
                        prop: "totalScore",
                        align: "center",
                    },
                    {
                        label: "年级排名",
                        prop: "totalGradeRank",
                        align: "center",
                    },
                    {
                        label: "班级排名",
                        prop: "totalClassRank",
                        align: "center",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        align: "center",
                        labelWidth: "150",
                    },
                ],
                check: false,
                border: false,
                height: 510,
            },
            score_total: 0,
            scorelistQuery: {
                pageNum: 1,
                pageRow: 20,
            },
            // 表头
            backgroundHeader: "#FAFBFC",
            // 表头字体
            headerColor: "",
            schoolId: "",
            levelDepartmentList: [], // 级部列表
            gradeList: [],  // 年级列表
            classList: [],  // 班级列表
            tabList: [], // 标签列表
            currentTab: "", // 当前标签
            excessHeight: 137, // 有分页器高度
            excessHeight2: 95, // 没有分页器高度
            deptList: [], // 级部列表
            gradesList: [], // 年级列表
            classesList: [], // 班级列表
            oriTreeData: [], // 原始扁平数据
        };
    },
    computed: {
        ...mapState({
            gradeLevel: (state) => state.gradeLevel, // 年级层级
        }),
        manHeadImg() {
            return require('@/assets/images/imgErrorAdr.png');
        },
        womanHeadImg() {
            return require('@/assets/images/woman-headImg.png');
        },
        errorImg() {
            return require('@/assets/images/errorImg.png');
        },
    },
    created() {
        this.firstTime = false;
        this.schoolId = this.listQuery.schoolId = this.$store.state.schoolId;
        this.init();
    },
    activated() {
        if (this.firstTime) {
            this.init();
        }
    },
    deactivated() {
        this.firstTime = true;
    },
    mounted() {
        this.resizeHandlerHandle(0);
    },
    methods: {
        /**
         * @Description: 获取计算页面高度
         * @Author: 闫乔
         * @Date: 2024-10-20 09:04:02
         */        
        resizeHandlerHandle(delHeight) {
            this.$nextTick(() => {
                if (this.$refs.tableWrap) {
                    console.log('document.body.clientHeight',document.body.clientHeight,'this.$refs.tableWrap.$el.offsetTop',this.$refs.tableWrap.offsetTop);
                    
                    let height = document.body.clientHeight - this.$refs.tableWrap.offsetTop - delHeight;
                    if(document.getElementsByClassName('pagination-container')[0]) {
                        height -= this.excessHeight
                    } else {
                        height -= this.excessHeight2
                    }
                    this.$refs.tableWrap.style.height = height + "px";
                }
            });
        },
        /**
         * @Description: 标签行的滚动事件
         * @Author: 闫乔
         * @Date: 2024-10-20 09:06:28
         * @param {*} e
         */        
        changeScroll(e) {
            let wrap = this.$refs.scroll.$refs.wrap;
            wrap.scrollLeft = wrap.scrollLeft - e.wheelDelta;
        },
        /**
         * @Description: 数据初始化事件
         * @Author: 闫乔
         * @Date: 2024-10-20 09:06:58
         */        
        async init() {
            
            this.schoolId = this.listQuery.schoolId = this.$store.state.schoolId;
            await this.initAssignment();
        },
        /**
         * @Description: 初始化数据
         * @Author: 闫乔
         * @Date: 2024-10-17 16:36:47
         */        
        async initAssignment() {
            console.log('this.$route.query',this.$route.query.isRemerber);
            await this.getAllDataList();
            // 是从成绩返回回来的
            if (this.$route.query && this.$route.query.isRemerber) {
                this.getBeforeData();
            } else {
                this.getList();
            }
        },
        /**
         * @Description: 处理从sessionStorage中取值，保留之前的筛选项及打开弹窗
         * @Author: 闫乔
         * @Date: 2024-10-21 17:30:02
         */        
        getBeforeData() {
            // 去sessionStorage中取值
            if (JSON.parse(sessionStorage.getItem('studentScoreInfo'))) {
                let studentScoreInfo = JSON.parse(sessionStorage.getItem('studentScoreInfo'))
                let currentTab = JSON.parse(sessionStorage.getItem('currentTab'))
                // 保留之前的标签
                this.tabList.forEach((item, index) => {
                    if (item.id === currentTab.id) {
                        this.$set(item, "sel", true);
                        this.currentTab = item;
                    } else {
                        this.$set(item, "sel", false);
                    }
                });

                // 点击人员展示成绩详情弹窗
                this.handleDetail(studentScoreInfo);
            }
        },
        /**
         * @Description: 获取年级、级部、班级下拉列表
         * @Author: 闫乔
         * @Date: 2024-10-17 16:36:03
         */        
        async getAllDataList() {
            let classParams = {
                schoolId: this.schoolId,
                onlyClassType1: 1
            }
            await this._fet("/school/schoolOrgan/getAllGradeClassList", classParams).then((res) => {
                this.handleRes(res, () => {
                    this.oriTreeData = res.data.data;
                   // organType 1 部门 2 级部 3 年级 4 班级
                    this.levelDepartmentList = res.data.data.filter((item) => item.organType == '2');
                    this.gradeList = res.data.data.filter((item) => item.organType == '3');
                    this.classList = res.data.data.filter((item) => item.organType == '4');
                   
                    
                    switch (this.gradeLevel) {
                        case "7":
                            this.tabList = this.levelDepartmentList;  // tab展示级部
                            break;
                        case "6":
                            this.tabList = this.gradeList;  // tab展示年级
                            break;
                        case "5":
                            this.tabList = this.gradeList;  // tab展示年级
                            break;
                        default:
                            break;
                    }
                   
                    if (this.tabList && this.tabList.length > 0) {
                        this.isShowTopTabs = true;
                        this.$nextTick(() => {
                            this.resizeHandlerHandle(0);
                        })
                        if (this.$route.query && this.$route.query.isRemerber) {
                            let currentTab = JSON.parse(sessionStorage.getItem('currentTab'))
                            this.tabList.forEach((item, index) => {
                                if (item.id === currentTab.id) {
                                    this.$set(item, "sel", true);
                                    this.currentTab = item;
                                    if (this.gradeLevel == '7') {
                                        this.listQuery.levelId = this.currentTab.id;
                                    } else {
                                        this.listQuery.gradeId = this.currentTab.id;
                                    }
                                } else {
                                    this.$set(item, "sel", false);
                                }
                            });
                        } else {
                            this.tabList.forEach((item, index) => {
                                if (index == 0) {
                                    this.$set(item, "sel", true);
                                    this.currentTab = item;
                                    if (this.gradeLevel == '7') {
                                        this.listQuery.levelId = this.currentTab.id;
                                    } else {
                                        this.listQuery.gradeId = this.currentTab.id;
                                    }
                                } else {
                                    this.$set(item, "sel", false);
                                }
                            });
                        }
                    }
                    console.log('this.currentTab默认第一个标签',this.currentTab);
                    
                    // 处理查询条件
                    this.treeDatas = listToTree(res.data.data, {
                        parentKey: "parentOrg",
                    });
                    setTimeout(() => {
                        // 处理筛选项展示
                        this.setFormDataSelect();
                    },200)
                });
            });
        },
        /**
         * @Description: 处理筛选项展示
         * @Author: 闫乔
         * @Date: 2024-10-21 10:53:02
         */        
        setFormDataSelect() {
            console.log('准成熟之后的',this.treeDatas,this.currentTab);
            
            // 级部 > 年级 > 班级
            if (this.gradeLevel == '7') {
                this.formData.data.splice(1, 1);
                // 默认获取年级下拉数据
                this.getGradeList(this.currentTab.id,'grade');
            } else if (this.gradeLevel == '6') { // 年级> 级部 > 班级
                this.formData.data.splice(0, 1);
                this.getGradeList(this.currentTab.id,'levelId');
                // 默认获取级部下拉数据
            } else if (this.gradeLevel == '5') { // 年级 > 班级
                this.formData.data.splice(0, 2);
                this.getGradeList(this.currentTab.id,'class');
            }
            if (this.$route.query && this.$route.query.isRemerber) {
                let myFormData = JSON.parse(sessionStorage.getItem('formData'))
                // 保留之前的筛选项
                this.formData.data.forEach((item) => {
                    item.value = myFormData[item.key];
                    this.listQuery[item.key] = myFormData[item.key];
                });
                if (this.gradeLevel == '7') {
                    this.listQuery.levelId = this.currentTab.id;
                    if (this.listQuery.gradeId) {
                        this.getGradeList(this.listQuery.gradeId,'class');
                    }
                } else if (this.gradeLevel == '6') {
                    this.listQuery.gradeId = this.currentTab.id;
                    if (this.listQuery.levelId) {
                        this.getGradeList(this.listQuery.levelId,'class');
                    }
                } else {
                    this.listQuery.gradeId = this.currentTab.id;
                }
                this.getList(1); // 获取列表数据
            }
        },
        /**
         * @Description: 修改年级/级部，获取班级数据
         * @Author: 闫乔
         * @Date: 2024-10-21 10:53:24
         * @param {*} data
         */        
        changeSel(data) {
            // 修改统计范围
            // 判断学校层级
            // 级部 > 年级 > 班级
            if (this.gradeLevel == '7') {
                if (data.key === "gradeId") {
                    const classIndex = this.formData.data.findIndex(
                        (i) => i.key === "classIds",
                    );
                    this.formData.data[classIndex].value = [];
                    this.formData.data[classIndex].list = [];
                    if (data.value) {
                        // 处理班级下拉数据
                        this.getClassList(data.value);
                    }
                }
            } else if (this.gradeLevel == '6') { // 年级 > 级部 > 班级
                if (data.key === "levelId") {
                    const classIndex = this.formData.data.findIndex(
                        (i) => i.key === "classIds",
                    );
                    this.formData.data[classIndex].value = [];
                    this.formData.data[classIndex].list = [];
                    if (data.value) {
                        // 处理班级下拉数据
                        this.getClassList(data.value);
                    }
                }
            }
        },
        /**
         * @Description: 获取下拉框列表
         * @Author: 闫乔
         * @Date: 2024-10-20 15:02:26
         * @param {*} gradeId
         */        
        getGradeList(levelId,type) {
            console.log('levelId',levelId,type);
            if (levelId) {
                let currentGradeArr = [];
                this.oriTreeData.forEach((item) => {
                    if (item.parentOrg == levelId) {
                        currentGradeArr.push(item)
                    }
                })
                console.log('currentGradeArr',currentGradeArr);
                if (currentGradeArr && currentGradeArr.length > 0) {
                    if (type == 'levelId') {
                        let groupArray = currentGradeArr.filter(i => i.organType == "2")
                        console.log('groupArray',groupArray);
                        this.deptList = [];
                        groupArray.forEach((item) => {
                            this.deptList.push({
                                value: item.id,
                                label: item.name,
                            });
                        });
                        console.log('this.deptList',this.deptList);
                        this.formData.data.forEach((item) => {
                            if (["levelId"].includes(item.key)) {
                                item.list = this.deptList;
                            }
                        })
                    } else if (type == 'grade') {
                        let gradeArray = currentGradeArr.filter(i => i.organType == "3")
                        console.log('gradeArray',gradeArray);
                        this.gradesList = [];
                        gradeArray.forEach((item) => {
                            this.gradesList.push({
                                value: item.id,
                                label: item.name,
                            });
                        });
                        console.log('this.gradesList',this.gradesList);
                        this.formData.data.forEach((item) => {
                            if (["gradeId"].includes(item.key)) {
                                item.list = this.gradesList;
                            }
                        })
                    } else if (type == 'class') {
                        let classArray = currentGradeArr.filter(i => i.organType == "4")
                        console.log('classArray',classArray);
                        this.classesList = [];
                        classArray.forEach((item) => {
                            this.classesList.push({
                                value: item.id,
                                label: item.name,
                            });
                        });
                        console.log('this.classesList',this.classesList);
                        this.formData.data.forEach((item) => {
                            if (["classIds"].includes(item.key)) {
                                item.list = this.classesList;
                            }
                        })
                    }
                    
                }
                
            }
           
        },
        /**
         * @Description: 获取班级数据
         * @Author: 闫乔
         * @Date: 2024-10-20 14:26:18
         * @param {*} parentId
         */        
        getClassList(parentId) {
            console.log('parentId',parentId,'this.oriTreeData',this.oriTreeData);
            let currentClassArr = [];
            this.oriTreeData.forEach((item) => {
                if (item.parentOrg == parentId) {
                    currentClassArr.push(item)
                }
            })
            console.log('currentClassArr',currentClassArr);
            if (currentClassArr && currentClassArr.length > 0) {
                let classArray = currentClassArr.filter(i => i.organType == "4")
                console.log('classArray',classArray);
                this.classesList = [];
                classArray.forEach((item) => {
                    this.classesList.push({
                        value: item.id,
                        label: item.name,
                    });
                });
                console.log('this.classesList',this.classesList);
                this.formData.data.forEach((item) => {
                    if (["classIds"].includes(item.key)) {
                        item.list = this.classesList;
                    }
                })
            }
           
        },
        /**
         * @Description: 切换tab
         * @Author: 闫乔
         * @Date: 2024-10-17 17:15:51
         * @param {*} data
         */        
        changeTabs(data) {
            console.log('data',data);
            
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, "sel", true);
                    this.currentTab = item;
                } else {
                    this.$set(item, "sel", false);
                }
            });
            // 清空下拉筛选选中的值
            // 级部 > 年级 > 班级
            if (this.gradeLevel == '7') {
                this.listQuery.levelId = data.id;
                const gradeIdIndex = this.formData.data.findIndex(
                    (i) => i.key === "gradeId",
                );
                this.formData.data[gradeIdIndex].value = "";
                this.formData.data[gradeIdIndex].list = [];
                if (data.id) {
                    // 默认获取年级下拉数据
                    this.getGradeList(data.id,'grade');
                }
                const classIndex = this.formData.data.findIndex(
                    (i) => i.key === "classIds",
                );
                this.formData.data[classIndex].value = [];
                this.formData.data[classIndex].list = [];
                
            } else if (this.gradeLevel == '6') { // 年级> 级部 > 班级
                
                this.listQuery.gradeId = data.id;
                const groupIdIndex = this.formData.data.findIndex(
                    (i) => i.key === "levelId",
                );
                this.formData.data[groupIdIndex].value = "";
                this.formData.data[groupIdIndex].list = [];
                if (data.id) {
                    // 默认获取级部下拉数据
                    this.getGradeList(data.id,'levelId');
                }
                const classIdIndex = this.formData.data.findIndex(
                    (i) => i.key === "classIds",
                );
                this.formData.data[classIdIndex].value = [];
                this.formData.data[classIdIndex].list = [];

            } else if (this.gradeLevel == '5') { // 年级 > 班级
                
                this.listQuery.gradeId = data.id;
                const classIdIndex = this.formData.data.findIndex(
                    (i) => i.key === "classIds",
                );
                this.formData.data[classIdIndex].value = [];
                this.formData.data[classIdIndex].list = [];
                if (data.id) {
                    this.getGradeList(data.id,'class');
                }
            }
            // 清空学生姓名
            const studentNameIndex = this.formData.data.findIndex(
                (i) => i.key === "studentName",
            );
            this.formData.data[studentNameIndex].value = "";

            this.formData.data.forEach((item) => {
                this.listQuery[item.key] = item.value;
            });
            this.getList(1);
        },
        /**
         * @Description: 查询按钮点击事件
         * @Author: 闫乔
         * @Date: 2024-10-21 15:35:07
         * @param {*} ev
         */        
        clickBtn(ev) {
            switch (ev.item.fn) {
                case "primary":
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 获取页面列表数据
         * @Author: 闫乔
         * @Date: 2024-10-17 16:37:04
         * @param {*} t
         */        
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            console.log('this.listQuery',this.listQuery);
            this.loadingTable = true;
            this._fet("/school/schoolStudent/list4Page2", this.listQuery).then((res) => {
                this.handleRes(res, () => {
                    setTimeout(() => {
                        this.total = res.data.data.totalCount;
                        this.tableData = res.data.data.list;
                        
                        this.loadingTable = false;
                    }, 200);
                });
            });
        },
        /**
         * @Description: 点击人员展示成绩详情弹窗
         * @Author: 闫乔
         * @Date: 2024-10-20 15:41:41
         * @param {*} row
         */        
        handleDetail(row) {
            console.log('row当前学生',row);
            this.studentScoreInfo = row;
            this.getStudentScoreList();
            this.studentScoreDialogObj.dialogVisible = true;
        },
        /**
         * @Description: 学生成绩弹窗的关闭
         * @Author: 闫乔
         * @Date: 2024-10-20 12:00:52
         */        
        handleStudentScoreClose() {
            this.studentScoreDialogObj.dialogVisible = false;
            if (this.$route.query && this.$route.query.isRemerber) {
                let newQuery = this._.cloneDeep(this.$route.query)
                delete newQuery.isRemerber
                this.$router.replace({ query: newQuery })
            }
        },
        /**
         * @Description: 获取学生成绩列表数据
         * @Author: 闫乔
         * @Date: 2024-10-20 17:08:51
         */        
        getScoreList(lime) {
            this.scorelistQuery.pageNum = lime.page;
            this.scorelistQuery.pageRow = lime.limit;
            this.getStudentScoreList(1);
        },
        /**
         * @Description: 获取学生成绩列表数据
         * @Author: 闫乔
         * @Date: 2024-10-21 14:55:00
         * @param {*} isPaging
         */        
        getStudentScoreList(isPaging) {
            this.studentloadingTable = true;
            if (!isPaging) {
                this.scorelistQuery.pageNum = 1;
            }
            this.scorelistQuery.schoolId = this.$store.state.schoolId;
            this.scorelistQuery.onlyCode = this.studentScoreInfo.onlyCode;
            this._fet(
                "/school/schoolArchivesData/getStudentAchievement",
                this.scorelistQuery,
            )
                .then((res) => {
                    if (res.data.code === "200") {
                        this.scoreDataList = res.data.data.list;
                        this.score_total = res.data.data.totalCount;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.studentloadingTable = false;
                })
                .catch((err) => {
                    this.studentloadingTable = false;
                });
        },
        /**
         * @Description: 跳转到学生个人成绩详情
         * @Author: 闫乔
         * @Date: 2024-10-20 17:16:20
         * @param {*} data
         */        
        handleDetails(data) {
            let item = this.$route.query.item;
            this.activeItem = {...this.$route.query,item};
            this.$router.push({
                name: "ClassManageAnalysis",
                params: {
                    onlyCode: this.studentScoreInfo.onlyCode,
                    source: "StudentScoreList",
                    examId: data.id,
                    gradeId: data.gradeId,
                    activeItem: JSON.stringify(this.activeItem),
                    id: data.id,
                    examName: data.examName,

                },
            });
            sessionStorage.setItem('studentScoreInfo', JSON.stringify(this.studentScoreInfo));
            sessionStorage.setItem('formData', JSON.stringify(this.listQuery));
            sessionStorage.setItem('currentTab', JSON.stringify(this.currentTab));
        },
        /**
         * @Description: 处理请求返回的数据
         * @Author: 闫乔
         * @Date: 2024-10-20 17:09:05
         * @param {*} res
         * @param {*} fn
         */        
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    background-color: #fff;
    padding: 10px 20px 0;
    // border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}
.header-menus {
    height: 40px;
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
}
.tabbed-page-wrap {
    height: 40px;
    cursor: pointer;
    transition: all 0.3 ease;
    white-space: nowrap;
    position: relative;
    box-sizing: border-box;
    /deep/.el-scrollbar__wrap {
        margin-bottom: 0px;
    }
}
.tabbed-page-item-name {
    max-width: 160px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.tabbed-page-item {
    max-width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    overflow-y: hidden;
    line-height: 40px;
    margin-right: 5px;
    box-sizing: border-box;
    padding: 0 32px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2B2F33;
    background-color: #F8F8F8;
    border-radius: 6px 6px 0px 0px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.tabbed-page-item:hover {
    background-color:#DDE9FF;
    color: #2B2F33;
}
.tabbed-page-item.current {
    background-color: #FFFFFF;
    color: #3C7FFF;
    font-weight: bold;
}
.table-wrap {
    background-color: #fff;
    padding: 0 8px;
    overflow-x: hidden;
    overflow-y: auto;
    .table_list_box {
        display: flex;
        flex-wrap: wrap;
    }
    .each_box {
        width: 96px;
        height: 128px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #EDEFF0;
        margin-right: 26px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        margin: 12px;

        &:hover {
            background: #EBF5FF;
            border: 1px solid #3C7FFF;
            .each_name {
                font-weight: bold;
                font-size: 16px;
                color: #3C7FFF;
            }
        }
        .each_headImg {
            width: 80px;
            height: 80px;
            img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
            }
        }
        .each_name {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            text-align: center;
            margin-top: 6px;
            width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    
}
.no_data_box {
    width: 100%;
    height: 100%;
}
.error_box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .error_img_box {
        width: 180px;
        height: 160px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .error_text {
        margin-top: 10px;
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #AFB1B3;
    }
}
.content_class {
    ::v-deep .pagination-container {
        padding-top: 8px;
        box-shadow: 0px -1px 24px 0px rgba(0, 0, 0, 0.1);
        position: relative;
    }
    ::v-deep .el-tag.el-tag--info {
        max-width: 82px !important;
    }

}
.detail_box {
    overflow-x: hidden;
    ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
        overflow-x: hidden;
    }
    ::v-deep .table-data .el-table td.el-table__cell, .table-data .el-table th.el-table__cell {
        border-bottom: 1px solid #EBEEF5 !important;
    }
    ::v-deep .el-dialog .el-dialog__body {
        padding: 0px 30px 20px !important;
    }

}
.detail_head {
    height: 104px;
    background: #F7F7F7;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .info_img {
        margin-left: 12px;

        .img_style {
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }
    }

    .info_nameSex {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        color: #6d7073;
        line-height: 32px;
        margin-left: 24px;
        .info_name{
            display: flex;
            &>div:nth-of-type(1){
                flex-shrink: 0;
                width: 60px;
                text-align: right;
            }
        }
        .info_text {
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 18px;
            color: #2B2F33;
            line-height: 24px;
            margin-bottom: 5px;
        }
        .info_content {
            display: flex;
            align-items: center;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #2B2F33;
            line-height: 24px;
            .info_item {
                width: 280px;
                .each_item {
                    display: flex;
                    align-items: center;
                    .info_title {
                        width: 70px;
                    }
                    .text {
                        width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
</style>
